<template>
  <div class="container-autenticacion py-md-8 py-lg-12">
    <v-container>
      <v-row class="align-md-center">
        <v-col sm="12" md="4" lg="6">
          <div class="img-container">
              <v-img
              alt="Vuetify Logo"
              class="shrink"
              contain
              :src="require('@/assets/crear-menu.png')"
              width="100%"
            />
          </div>
        </v-col>
        <v-col sm="12" md="4" lg="6">
          <v-form
            ref="form"
            @submit.prevent="autenticarUsuarios()"
            v-model="valid"
            lazy-validation
            class="pa-8"
          >
            <h4 class="mb-8 text-h4 font-weight-regular">Iniciar sesión</h4>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Correo"
              required
              color="black"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              color="black"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="password"
              label="Contraseña"
              @click:append="show1 = !show1"
              outlined
            ></v-text-field>
            <div class="forget-password mb-4">
              <router-link
                to="/recuperar-contrasenia"
                class="
                  text-subtitle-1
                  link-olvide
                  greenOpacity--text
                  d-inline-block
                "
                >¿Olvidaste tu contraseña?</router-link
              >
            </div>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              class="white--text py-7 pr-9 pl-9 btn-orange mb-8"
              type="submit"
              rounded
              >Ingresar</v-btn
            >
            <p class="errorColor--text" v-if="errorMessage">
              {{ errorMessage }}
            </p>
            <p class="black--text success-text" v-if="estatusRegistro">
              {{ estatusRegistro }}
            </p>
            <p class="black--text text-h6 font-weight-regular">
              ¿No tienes una cuenta?
            </p>
            <a
              href="#"
              v-on:click.prevent="cambiarAutenticacion"
              class="greenOpacity--text link-registro text-button"
              >Registrate
              <div class="icon-arrow-registro ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.414"
                  height="14.828"
                  viewBox="0 0 8.414 14.828"
                >
                  <path
                    data-name="Trazado 1550"
                    d="m18 6.2 6 6-6 6"
                    transform="translate(-16.586 -4.784)"
                    style="
                      fill: none;
                      stroke: #2b604b;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 2px;
                    "
                  />
                </svg>
              </div>
            </a>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    valid: true,
    email: "",
    password: "",
    show1: false,
    errorMessage: "",
    loading: false,
    rules: {
      required: (value) => !!value || "La contraseña es requerida.",
      min: (v) =>
        v.length >= 6 || "La contraseña debe tener al menos 6 caracteres",
    },
    emailRules: [
      (v) => !!v || "El correo es requerido",
      (v) => /.+@.+\..+/.test(v) || "El correo es incorrecto",
    ],
  }),
  computed: {
    ...mapState(["estatusRegistro"]),
  },
  methods: {
    ...mapActions(["setUsuario", "loginUsuarioCorreo"]),
    async autenticarUsuarios() {
      if (!this.$refs.form.validate()) return;
      const user = {
        email: this.email,
        password: this.password,
      };
      this.errorMessage = "";
      this.loading = true;
      const result = await this.loginUsuarioCorreo(user);
      if (!result.res) {
        this.errorMessage = result.error;
      }
      this.loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    cambiarAutenticacion() {
      this.$router.push("/registro");
    },
  },
};
</script>
<style lang="scss">
.link-olvide:hover {
  text-decoration: none;
}

.link-registro {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  line-height: 1 !important;
  .icon-arrow-registro {
    width: 16px;
    height: 15px;
    transition: all 300ms;
    svg {
      width: 100%;
    }
  }
  &:hover {
    text-decoration: none;
    .icon-arrow-registro {
      transform: translateX(8px);
    }
  }
}
</style>