<template>
  <v-container>
    <div class="container-autenticacion">
      <v-container>
        <v-row class="align-md-center">
          <v-col sm="12" md="4" lg="6">
            <v-img
              alt="Vuetify Logo"
              class="shrink"
              contain
              :src="require('@/assets/crear-menu.png')"
              width="90%"
            />
          </v-col>
          <v-col sm="12" md="4" lg="6">
            <v-form
              ref="formRecuperar"
              @submit.prevent="enviarCorreoRecuperar()"
              v-model="valid"
              lazy-validation
              class="pa-8"
            >
              <h4 class="mb-4 text-h4 font-weight-regular">
                ¿Tienes problemas para iniciar sesión?
              </h4>
              <p>
                Ingresa tu correo electrónico y te enviaremos un enlace para que
                recuperes el acceso a tu cuenta.
              </p>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Correo"
                required
                color="black"
                outlined
              ></v-text-field>
              <v-btn
                :disabled="!valid"
                :loading="loading"
                class="white--text py-7 pr-9 pl-9 btn-orange mb-8"
                type="submit"
                rounded
                >Enviar enlace</v-btn
              >
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      email: "",
      emailRules: [
        (v) => !!v || "El correo es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo es incorrecto",
      ],
      valid: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["recuperarContra"]),
    async enviarCorreoRecuperar() {
      if (!this.$refs.formRecuperar.validate()) return;
      this.loading = true;
      const result = await this.recuperarContra({ correo: this.email });
      if (result.res) {
        this.reset();
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se te envío un correo.",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    reset() {
      this.$refs.formRecuperar.reset();
    },
  },
};
</script>