<template>
  <div class="container-autenticacion py-md-8 py-lg-12">
    <v-container>
      <v-row class="align-md-center">
        <v-col sm="12" md="4" lg="6">
          <div class="img-container">
            <v-img
              alt="Vuetify Logo"
              class="shrink"
              contain
              :src="require('@/assets/hombreCaminando.png')"
              width="100%"
            />
          </div>
        </v-col>
        <v-col sm="12" md="4" lg="6">
          <v-form
            ref="form"
            @submit.prevent="autenticarUsuarios()"
            v-model="valid"
            lazy-validation
            class="pa-8"
          >
            <h4 class="mb-8 text-h4 font-weight-regular">
              Registrate con nosotros
            </h4>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Correo"
              required
              color="black"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              color="black"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="password"
              label="Contraseña"
              @click:append="show1 = !show1"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              color="black"
              :type="showConfirm ? 'text' : 'password'"
              :rules="[rules.required, rules.min, passwordConfirmationRule]"
              name="password"
              label="Confirmar Contraseña"
              @click:append="showConfirm = !showConfirm"
              outlined
            ></v-text-field>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              class="white--text py-7 pr-9 pl-9 btn-orange"
              type="submit"
              rounded
              >Registrarte</v-btn
            >
            <p class="errorColor--text" v-if="errorMessage">
              {{ errorMessage }}
            </p>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      valid: true,
      email: "",
      password: "",
      show1: false,
      confirmPassword: "",
      showConfirm: false,
      errorMessage: "",
      loading: false,
      rules: {
        required: (value) => !!value || "La contraseña es requerida.",
        min: (v) =>
          v.length >= 6 || "La contraseña debe tener al menos 6 caracteres",
      },
      emailRules: [
        (v) => !!v || "El correo es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo es incorrecto",
      ],
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "La contraseña no coincide.";
    },
  },
  methods: {
    ...mapActions(["registroUsuarioCorreo"]),
    async autenticarUsuarios() {
      if (!this.$refs.form.validate()) return;
      const user = {
        email: this.email,
        password: this.password,
      };
      this.errorMessage = "";
      this.loading = true;
      const result = await this.registroUsuarioCorreo(user);
      if (result.res) {
        this.cambiarAutenticacion();
      } else {
        this.errorMessage = result.error;
      }
      this.loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    cambiarAutenticacion() {
      this.$router.push("/login");
    },
  },
};
</script>